const INTER = [
    {
        space_src: 'baidujs',
        space_type: 'inters',
        space_id: 'u6965404',
        space_js: '//h5fpb1.sdlads.cn/common/mhgp/common/g/production/ed/n-d.js'
    }];
const BANNER = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6965405',
        space_js: '//h5fpb1.sdlads.cn/common/n/i_hq_hfeof.js'
    }
];
const NATIVE_1 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6965406',
        space_js: '//h5fpb1.sdlads.cn/source/ojir/production/i/gf/static/pi.js'
    }
];
const NATIVE_2 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6965407',
        space_js: '//h5fpb1.sdlads.cn/common/p_k/source/js_jhgq_l.js'
    }
];
const NATIVE_3 = [
    {
        space_src: 'baidujs',
        space_type: 'native',
        space_id: 'u6965408',
        space_js: '//h5fpb1.sdlads.cn/source/q_l/kt/kih/resource/rn.js'
    }
];


/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_banner: BANNER,
    Main_native: NATIVE_1,
    Classroom_native: NATIVE_2,
    Wikipedia_cat_native: NATIVE_1,
    Wikipedia_dog_native: NATIVE_2,
    Wikipedia_other_native: NATIVE_3,
    ConstellationList_banner: BANNER,
    ConstellationList_native: NATIVE_1,
    ArticleDetail_inter: INTER,
    ArticleDetail_banner: BANNER,
    ArticleDetail_native: NATIVE_1,
    PetDetail_inter: INTER,
    PetDetail_banner: BANNER,
    PetDetail_native: NATIVE_1,
    Mood_dialog: NATIVE_3,
};
